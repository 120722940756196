import { constants as c } from "../constants";
import { getToken } from "../utils/auth";

const token = getToken();
// API PHÒNG CHAT
function getRoomChatList(queryString = "", tokenInput) {

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInput ?? token ,
    },
  };
  return fetch(`${c.API_URL}/chat/room_chats${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getRoomChatListByUserId(queryString) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  
  return fetch(`${c.API_URL}/chat/manager/room_chats${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createRoomChat(params) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
    body: JSON.stringify(params),
  };
  return fetch(`${c.API_URL}/chat/room_chats`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

// tạo hàm xóa phòng chat
function deleteRoomChat(roomId) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  return fetch(`${c.API_URL}/chat/room_chats/${roomId}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => console.log(err));
}

function updateRoomChat(roomId, params) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
    body: JSON.stringify(params),
  };
  return fetch(`${c.API_URL}/chat/room_chats/${roomId}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function getOneRoomChat(roomId, queryString) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  return fetch(`${c.API_URL}/chat/room_chats/${roomId}${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => console.log(err));
}

function updateMemberRoomChat(roomId, params) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: token,
    }, 
    body: JSON.stringify(params),
  };
  return fetch(`${c.API_URL}/chat/room_chats/${roomId}/members`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function searchMembers(queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  return fetch(`${c.API_URL}/chat/room_chats/members/search${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

// API TIN NHẮN
function getMessageList(roomId, queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  return fetch(`${c.API_URL}/chat/messages/${roomId}${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function createMessage(params) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
    body: JSON.stringify(params),
  };
  return fetch(`${c.API_URL}/chat/messages`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function updateMessage(params) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
    body: JSON.stringify(params),
  };
  return fetch(`${c.API_URL}/chat/messages`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function addPinMessage(params) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
    body: JSON.stringify(params),
  };
  return fetch(`${c.API_URL}/chat/pin_messages`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function redirectMessage(params) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
    body: JSON.stringify(params),
  };
  return fetch(`${c.API_URL}/chat/messages/redirect`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function callPageMessage(messageId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  return fetch(`${c.API_URL}/chat/messages/${messageId}/cal_page`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}


function getFileList(queryString = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  return fetch(`${c.API_URL}/chat/files/${queryString}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
}

function uploadImage(params) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
    body: JSON.stringify(params),
  };
  return fetch(`${c.API_URL}/upload/images`, requestOptions)
}

export const chatServices = {
  getRoomChatList,
  createMessage,
  uploadImage,
  updateMessage,
  addPinMessage,
  getMessageList,
  searchMembers,
  redirectMessage,
  getFileList,
  updateRoomChat,
  callPageMessage,
  updateMemberRoomChat,
  createRoomChat,
  deleteRoomChat,
  getOneRoomChat,
  getRoomChatListByUserId
};
