import { callApi } from "../apis"

const getReport = (param) => {
  return callApi(`/franchise/customer/report${param}`, "get");
};

const getReportCate = (form) => {
  return callApi(`/franchise/report-cate`, "get");
};

const getReportDetail = (id) => {
  return callApi(`/franchise/report/${id}`, "get");
};

const addReport = (form) => {
  return callApi(`/franchise/report`, "post",form);
};

export const report = {
  getReport,
  getReportCate,
  getReportDetail,
  addReport,
};
