import { Menu } from "antd";
import LOGO from "../../assets/images/logo-header.png";
import { StyledLogo, StyledSidebar } from "./Sidebar.style";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  CarOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  HomeOutlined,
  ReconciliationOutlined,
  ShopOutlined,
  ShoppingOutlined,
  UserOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import {
  FinanceIcon,
  NewsIcon,
  ProductIcon,
  SellerIcon,
  SettingIcon,
  VoucherIcon,
} from "../../assets/icons";
import StartIcon from "../../assets/icons/StartIcon";
import { useEffect } from "react";
import { dataAppStore } from "../../store/dataAppStore";
import Sider from "antd/es/layout/Sider";
// import { useOrderStore } from '../../store/orderStore.js';
// import { paymentStatusCodeMenu } from '../../constants/paymentStatusCode.js';

const Sidebar = ({ collapsed }) => {
  const badges = JSON.parse(localStorage.getItem("badges"));
  const path = window.location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("beforeunload", () => {});
    return () => {
      window.removeEventListener("beforeunload", () => {});
    };
  }, [navigate]);

  const { setTitle, badge } = dataAppStore((state) => state);
  const {
    meeting_processing,
    meeting_accepted,
    mail_unread,
    report_count_new,
    file_count_new,
  } = badge;

  const menuSidebar = [
    {
      key: "/",
      icon: <DashboardOutlined style={{ color: "#4595ef" }} />,
      label: (
        <Link
          className="flex justify-between"
          to=""
          onClick={(e) => {
            setTitle("Tổng quan");
          }}
        >
          Tổng quan
        </Link>
      ),
    },
    {
      key: "/reports",
      icon: <SellerIcon style={{ color: "#ff98aa" }} className="w-[16px]" />,
      label: (
        <Link
          to="reports"
          className="flex justify-between"
          onClick={(e) => {
            setTitle("Báo cáo");
          }}
        >
          Báo cáo{" "}
          <span className="font-medium">
            {report_count_new == 0 || report_count_new === undefined
              ? ""
              : `(${report_count_new})`}
          </span>
        </Link>
      ),
    },
    {
      key: "/mails",
      // icon: <StoreIcon classNstyle={{color: "#ff98aa"}}ame="w-[16px]"/>,
      icon: <ShopOutlined style={{ color: "red" }} />,
      label: (
        <Link
          className="flex justify-between"
          to="mails"
          onClick={(e) => {
            setTitle("Trao đổi thông tin");
          }}
        >
          Trao đổi thông tin{" "}
          <span className="font-medium">
            {mail_unread == 0 || mail_unread === undefined
              ? ""
              : `(${mail_unread})`}
          </span>
        </Link>
      ),
    },
    {
      key: "/files",
      icon: <FileDoneOutlined style={{ color: "#9a10d0" }} />,
      label: (
        <Link
          className="flex justify-between"
          to="files"
          onClick={(e) => {
            setTitle("Tài liệu lưu trữ");
          }}
        >
          Tài liệu lưu trữ{" "}
          <span className="font-medium">
            {file_count_new == 0 || file_count_new === undefined
              ? ""
              : `(${file_count_new})`}
          </span>
        </Link>
      ),
    },
    {
      key: "/meetings",
      icon: <UserOutlined style={{ color: "#52dc07" }} />,
      label: (
        <Link
          className="flex justify-between"
          to="/meetings"
          onClick={(e) => {
            setTitle("Lịch họp");
          }}
        >
          Lịch họp{" "}
          <span className="font-medium">
            {meeting_processing == 0 || meeting_processing === undefined
              ? ""
              : `(${meeting_processing})`}
          </span>
        </Link>
      ),
    },
    {
      key: "/posts",
      icon: <VoucherIcon style={{ color: "red" }} className="w-[16px]" />,
      label: (
        <Link
          className="flex justify-between"
          to="/posts"
          onClick={(e) => {
            setTitle("Thông tin, tin tức");
          }}
        >
          Thông tin, tin tức{" "}
          {/* <span className="font-medium">
            {meeting_processing == 0 ? "" : `(${meeting_processing})`}
          </span> */}
        </Link>
      ),
    },
    {
      key: "/helps",
      icon: <ShoppingOutlined style={{ color: "#03aaff" }} />,
      label: (
        <Link
          className="flex justify-between"
          to="/helps"
          onClick={(e) => {
            setTitle("Câu hỏi thường gặp");
          }}
        >
          Câu hỏi thường gặp
        </Link>
      ),
    },
    {
      key: "/contacts",
      icon: <ShoppingOutlined style={{ color: "#03aaff" }} />,
      label: (
        <Link
          className="flex justify-between"
          to="/contacts"
          onClick={(e) => {
            setTitle(" Liên hệ hỗ trợ");
          }}
        >
          Liên hệ hỗ trợ
        </Link>
      ),
    },
  ];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={!collapsed}
      width={300}
      theme="light"
    >
      <StyledLogo>
        <img
          src={LOGO}
          alt="logo cms"
          width={!collapsed ? 50 : "auto"}
          height={"auto"}
          style={{
            maxHeight: "50px",
          }}
        />
      </StyledLogo>
      <Scrollbars
        style={{ height: "calc(100vh - 64px)" }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
      >
        <Menu
          items={menuSidebar}
          theme="light"
          mode="inline"
          defaultSelectedKeys={[path]}
          selectedKeys={[path]}
          onClick={(e) => {}}
          style={{
            margin: "16px 0",
            minHeight: "100vh - 80px",
            background: "#fff",
          }}
        />
      </Scrollbars>
    </Sider>
  );
};

export default Sidebar;
