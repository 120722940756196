import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledMessage } from "./Message.style";
import { handleShowTime, handleShowTimeMess } from "../../../../utils/date";
import { chatActions as a } from "../../../../actions/chatActions";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Dropdown, Image, Modal } from "antd";
import { toast } from "react-toastify";
import {
  CopyIcon,
  DeleteIcon,
  EmojiIcon,
  PinIcon,
  ReplyIcon,
  ShareIcon,
} from "../../icons";
import { FileDownloader } from "../fileDownloader";
import { handleShowTypeMessage } from "../../utils";
import Linkify from "react-linkify";
import ImageLists from "../ImageList";
import ModalEmotion from "./ModalEmotion";
import Highlight from "react-highlight-words";
import { dataAppStore } from "../../../../store/dataAppStore";

const typeMess = {
  TEXT: "text",
  IMAGE: "image",
  FILE: "custom",
  VIDEO: "video",
  GIF: "gif",
};

const Message = ({
  message,
  nextMessage,
  prevMessage,
  handleMoveToMessage,
}) => {
  const dispatch = useDispatch();
  const roomSelected = useSelector((state) => state.chat.roomSelected);
  const { profile } = dataAppStore((state) => state);
  const { is_group } = roomSelected;
  const {
    content,
    updated_at,
    created_at,
    status,
    type,
    user,
    message_reply,
    message_file,
    hide_all,
    feel,
  } = message;
  const { id, avatar, latest_req_time, name } = user;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isShowAvatar = nextMessage?.user?.id === id;
  const isSamePerson = prevMessage?.user?.id === id;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        toast.success("Đã sao chép tin nhắn");
      })
      .catch((error) => {
        toast.error("Có lỗi sao chép:", error);
      });
  };

  const onSelectReplyMessage = () => {
    dispatch(a.handleSelectReplyMessage(message));
  };

  const onPinMessage = () => {
    const params = {
      room_chat_id: roomSelected.id,
      message_id: message.id,
    };
    dispatch(a.handleAddPinMessage(params));
  };

  const onDeleteMessage = () => {
    const messageUpdate = {
      ...message,
      hide_for_user: true,
      hide_all: true,
      user_id: message.user.id,
      message_id: message.id,
    };
    dispatch(a.updateMessage(messageUpdate));
  };

  const onChooseEmoji = (emoji) => {
    const messageUpdate = {
      ...message,
      user_id: profile.id,
      reaction: emoji,
      message_id: message.id,
    };
    dispatch(a.updateMessage(messageUpdate));
  };

  const onRedirectMessage = () => {
    dispatch(
      a.toggleModalSelectsUser({ isOpen: true, type: "forward", data: message })
    );
  };

  const items = [
    {
      label: (
        <div
          className="d-flex align-item-center gap-3"
          onClick={onSelectReplyMessage}
        >
          <ReplyIcon style={{ color: "#72777B" }} />
          <p style={{ marginLeft: "16px" }}>Trả lời</p>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          className="d-flex align-item-center gap-3"
          onClick={copyToClipboard}
        >
          <CopyIcon />
          <p style={{ marginLeft: "16px" }}>Copy tin nhắn</p>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="d-flex align-item-center gap-3" onClick={onPinMessage}>
          <PinIcon />
          <p style={{ marginLeft: "16px" }}>Ghim</p>
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div
          className="d-flex align-item-center gap-3"
          onClick={onRedirectMessage}
        >
          <ShareIcon />
          <p style={{ marginLeft: "16px" }}>Chuyển tiếp</p>
        </div>
      ),
      key: "3",
    },
    {
      label:
        profile.id === message.user.id ? (
          <div
            className="d-flex align-item-center action-item__delete"
            onClick={onDeleteMessage}
          >
            <DeleteIcon />
            <p style={{ marginLeft: "16px" }}>Thu hồi</p>
          </div>
        ) : null,
      key: "4",
    },
  ];

  
  const handleOpenModalUserInfo = (user) => {
    dispatch(a.toggleModalUserInfo({ isOpen: true, data: user }));
  };

  const onTagClick = async (tagName, userHightlight) => {
    if(tagName === "@Tất cả") return ;
    const result = await userHightlight.find(item => item.value === tagName);
    dispatch(a.toggleModalUserInfo({ isOpen: true, data: result.user }));
  }

  const HighlightString = (text) => {
    const { user_tag_ids } = message;
    let searchWords = []
    let userHightlight = []
    if(user_tag_ids?.length && Number(user_tag_ids[0]) === -2) searchWords = ["@Tất cả"];
    else {
      const newUserTagIds = user_tag_ids?.map((id) => Number(id));
      if (!user_tag_ids?.length) return <div>{text}</div>;
      userHightlight = roomSelected.users.filter((user) => newUserTagIds.includes(user.id)).map((user) => ({id: user.id, user: user, value: `@${user.name}`}));
      searchWords = userHightlight.map((user) => user.value);
    }

    return (
      <Highlight
        searchWords={searchWords}
        textToHighlight={text}
        highlightTag={({ children }) => (
          <span style={{ color: "blue", cursor: "pointer" }} onClick={() => onTagClick(children, userHightlight)}>{children}</span>
        )}
      >
      </Highlight>
    );
  };

  const renderContentMess = () => {
    if (typeMess.TEXT === type)
      return (
        <pre
          className="message-content__content"
          style={{
            fontFamily: "Mulish, sans-serif",
            whiteSpace: "pre-wrap",
            marginBottom: !handleShowTime(created_at) ? "0px" : "8px",
          }}
        >
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {/* <div>{content}</div> */}
            {HighlightString(content)}
            {/* <HighlightString text={content}/> */}
          </Linkify>
        </pre>
      );
    if (typeMess.IMAGE === type) {
      return (
        <div className="message-content__content">
          {message_file.length ? <ImageLists images={message_file} /> : null}
        </div>
      );
    }
    if (typeMess.FILE === type) {
      return message_file?.length
        ? message_file.map((file) => {
            return (
              <div className="message-content__content" style={{ paddingBottom: "4px" }}>
                <FileDownloader fileUrl={file.url_file} fileName={file.name} />
              </div>
            );
          })
        : null;
      // <div className="message-content__content">
      //   <FileDownloader
      //     fileUrl={message_file.length ? message_file[0]?.url_file : ""}
      //     fileName={message_file[0]?.name}
      //   />
      // </div>
    }
    if (typeMess.VIDEO === type) {
      return (
        <div className="message-content__content">
          <video
            src={message_file.length ? message_file[0].url_file : ""}
            controls
            style={{ width: "300px" }}
          />
        </div>
      );
    }
    if (typeMess.GIF === type) {
      return (
        <div className="message-content__content">
          <img src={content} alt="gif" />
        </div>
      );
    }
  };

  const handleShowTime = (time) => {
    if (
      prevMessage?.user?.id === id &&
      handleShowTimeMess(time) === handleShowTimeMess(prevMessage?.created_at)
    ) {
      return false;
    }
    return true;
  };

  const handleSendEmoji = (emoji) => {
    const smileHexCode = emoji;
    const smileDecimalCode = parseInt(smileHexCode, 16);

    const smileIcon = String.fromCodePoint(smileDecimalCode);

    console.log(smileIcon);
    onChooseEmoji(smileIcon);
  };

  const renderEmotionList = () => {
    const items = ["2764", "1F602", "1F625", "1F621", "1F632", "1F44D"];

    return (
      <div
        style={{
          background: "white",
          padding: "4px 16px",
          boxShadow: "0 1px 2px #00000033",
          borderRadius: "20px",
          display: "flex",
          gap: "8px",
          alignItems: "center",
        }}
      >
        {items.map((item) => {
          return (
            <div
              style={{
                cursor: "pointer",
                fontSize: "20px",
              }}
              dangerouslySetInnerHTML={{ __html: `&#x${item}` }}
              onClick={() => handleSendEmoji(item)}
            />
          );
        })}
      </div>
    );
  };

  const groupReactions = (reactions) => {
    const groupedReactions = [];
    const users = roomSelected.users;

    reactions.forEach((reaction) => {
      const existingReaction = groupedReactions.find(
        (groupedReaction) => groupedReaction.reaction === reaction.reaction
      );
      const userObject = users.find((user) => user.id === reaction.user_id);
      if (existingReaction) {
        // Biểu cảm đã tồn tại, cập nhật thông tin
        existingReaction.users.push(userObject);
        existingReaction.count += 1;
      } else {
        // Biểu cảm chưa tồn tại, thêm vào mảng mới
        groupedReactions.push({
          reaction: reaction.reaction,
          users: [userObject],
          count: 1,
        });
      }
    });
    groupedReactions.sort((a, b) => b.count - a.count);

    return groupedReactions;
  };

  const groupAllFell = (feel) => {
    const groupedReactions = [];

    const users = roomSelected.users;
    feel.forEach((reaction) => {
      const existingReaction = groupedReactions.find(
        (groupedReaction) => groupedReaction.reaction === reaction.reaction
      );
      const userObject = users.find((user) => user.id === reaction.user_id);
      groupedReactions.push({
        reaction: reaction.reaction,
        users: [userObject],
      });
    });

    return groupedReactions;
  };

  const handleMarginBottom = () => {
    if (message?.feel?.length) return "14px";
    if (!handleShowTime(created_at)) return "4px";
    if (handleShowTime(created_at)) return "10px";
  };

  return (
    <StyledMessage>
      <div
        className={`message ${isShowAvatar ? "isShowAvatar" : ""} ${
          profile?.id === id ? "send" : ""
        } ${isSamePerson ? "isSamePerson" : ""}`}
        style={{ marginBottom: handleMarginBottom() }}
      >
        {profile?.id !== id && (
          <div
            className="message-avatar"
            onClick={() => handleOpenModalUserInfo(user)}
            style={{ cursor: "pointer" }}
          >
            <img
              src={
                avatar ||
                "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg";
              }}
              alt="avatar"
            />
          </div>
        )}
        <div
          className={`message-content ${profile?.id === id ? "send" : ""} ${
            type === typeMess.TEXT ? "isText" : ""
          } ${hide_all ? "hide-all" : ""}`}
        >
          {hide_all ? (
            <>
              {is_group && !isShowAvatar && (
                <p className="message-content__name">{name}</p>
              )}
              <p className="message-revoked">Tin nhắn đã được thu hồi</p>
            </>
          ) : (
            <>
              {message_reply?.id && (
                <div
                  className="message-reply"
                  onClick={() => handleMoveToMessage(message_reply.id)}
                >
                  <div className="message-reply__content">
                    <p style={{ fontWeight: "600" }}>
                      {message_reply.user.name}
                    </p>
                    <p style={{ marginTop: "2px", color: "#7589a3" }}>
                      {handleShowTypeMessage(
                        message_reply.content,
                        message_reply.type
                      )}
                    </p>
                  </div>
                </div>
              )}
              {is_group && !isShowAvatar && (
                <p className="message-content__name">{name}</p>
              )}
              {renderContentMess()}
              {handleShowTime(created_at) && (
                <p className="message-content__time">
                  {handleShowTimeMess(created_at)}
                </p>
              )}
              <div className="message-content__action">
                <Dropdown
                  menu={{ items }}
                  trigger={["hover"]}
                  placement={profile?.id === id ? "bottomRight" : "bottomLeft"}
                >
                  <MoreHorizIcon fontSize="small" color="#7589a3" />
                </Dropdown>
              </div>
              <div className="message-content__emotion">
                <Dropdown
                  // menu={{ items }}
                  trigger={["hover"]}
                  placement={"top"}
                  dropdownRender={() => renderEmotionList()}
                >
                  <EmojiIcon />
                </Dropdown>
              </div>
              {feel && feel.length && (
                <div
                  className="message-show-emotion"
                  onClick={() => setIsModalOpen(true)}
                >
                  {groupReactions(feel)
                    .slice(0, 2)
                    .map((item) => {
                      return (
                        <div style={{ color: "red" }}>{item.reaction}</div>
                      );
                    })}
                  <span style={{ fontSize: "13px" }}>{feel.length}</span>
                </div>
              )}

              {isModalOpen && (
                <Modal
                  title="Biểu cảm"
                  open={isModalOpen}
                  getContainer={"#box-chat-container"}
                  onCancel={() => setIsModalOpen(false)}
                  footer={null}
                  width={500}
                  styles={{ minHeight: "400px" }}
                >
                  <ModalEmotion
                    feel={groupAllFell(feel)}
                    groupReactions={groupReactions(feel)}
                  />
                </Modal>
              )}
            </>
          )}
        </div>
      </div>
    </StyledMessage>
  );
};
export default React.memo(Message);
