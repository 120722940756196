import styled from "styled-components";

export const StyledMainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .header-chat-container {
    // position: relative;
    display: flex;
    flex-direction: column;
    // padding: 10px;
    border-bottom: 1px solid #ddd;
    .chat-info {
      display: flex;
      align-items: center;
      &__avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      &__name {
        font-size: 18px;
        font-weight: 600;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .number-member {
          font-size: 12px;
          font-weight: 400;
          color: #97a4b5;
          margin-top: 6px
        }
      }
    }
    .chat-action {
      display: flex;
      gap: 10px;
      align-items: center;
      .btn {
        // width: 32px;
        // height: 32px;
        padding: 6px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #dfe2e7;
        }
      }
      .room-chat-pin {
        background-color: #fff;
        width: 200px;
        .header {
          padding: 4px 10px;
          background-color: #f0f7ff;
          font-size: 11px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .message-list {
          .message {
            padding: 6px 10px;
            display: flex;
            gap: 2px;
            align-items: center;
            border-radius: 8px;
            &:hover {
              background-color: #f3f5f6;
              cursor: pointer;
            }
            &__avatar {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            &__content {
              font-size: 12px;
              color: #000;
              opacity: 0.8;
              border-left: 1px solid #0068ff;
              padding-left: 8px;
            }
          }
        }
      }
    }
    .text-truncate-1 {
      display: block;
      // display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 280px;
      height: 20px;
      line-height: 20px;
    }
  }
`;
