import { callApi } from "../apis"

const getHelp = (param) => {
  return callApi(`/franchise/help${param}`, "get");
};

const getHelpDetail = (id) => {
  return callApi(`/franchise/help/${id}`, "get");
};

export const help = {
  getHelp,
  getHelpDetail,
};
