import { callApi } from "../apis"

const getMeeting = (param) => {
  return callApi(`/franchise/customer/meeting${param}`, "get");
};

const getMeetingDetail = (id) => {
  return callApi(`/franchise/meeting/${id}`, "get");
};

const acceptOrCancel = (id, form) => {
  return callApi(`/franchise/meeting/accept/${id}`, "put", form);
};

export const meeting = {
  getMeeting,
  getMeetingDetail,
  acceptOrCancel,
};
