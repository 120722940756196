import { create } from "zustand";
import { RepositoryRemote } from "../services";
import { removeToken } from "../utils/auth";
import { dataAppStore } from "./dataAppStore";

export const useAuthStore = create((set) => ({
  tokenInfo: {},
  loading: false,
  phone_number: "",
  login: async (form, onSuccess, onFail) => {
    try {
      set({ loading: true });
      const response = await RepositoryRemote.auth.login(form);
      set({ tokenInfo: response.data.data });
      onSuccess(response.data.data.token);
    } catch (error) {
      onFail(error.response.data.msg || "Có lỗi xảy ra!");
    }
    set({ loading: false });
  },
  register: async (form, onSuccess, onFail) => {
    try {
      set({ loading: true });
      const response = await RepositoryRemote.auth.register(form);
      onSuccess(response);
    } catch (error) {
      onFail(error);
    }
    set({ loading: false });
  },
  checkExists: async (form, onSuccess, onFail) => {
    try {
      set({ loading: true });
      const response = await RepositoryRemote.auth.checkExists(form);
      set({ phone_number: form?.phone_number });
      onSuccess(response);
    } catch (error) {
      onFail(error);
    }
    set({ loading: false });
  },
  resetPassword: async (form, onSuccess, onFail) => {
    try {
      set({ loading: true });
      const response = await RepositoryRemote.auth.resetPassword(form);
      onSuccess(response);
    } catch (error) {
      onFail(error);
    }
    set({ loading: false });
  },
  sendOtp: async (form, onSuccess, onFail) => {
    try {
      set({ loading: true });
      const response = await RepositoryRemote.auth.sendOtp(form);
      onSuccess(response);
    } catch (error) {
      onFail(error);
    }
    set({ loading: false });
  },
  sendEmailOtp: async (form, onSuccess, onFail) => {
    try {
      set({ loading: true });
      const response = await RepositoryRemote.auth.sendEmailOtp(form);
      onSuccess(response);
    } catch (error) {
      onFail(error);
    }
    set({ loading: false });
  },
  logOut: async (onSuccess, onFail) => {
    try {
      removeToken();
      // localStorage.removeItem("profile")
      // localStorage.removeItem("badges")
      // localStorage.removeItem("cartInfo")
      set({ tokenInfo: {} });
      //onSuccess(response);
    } catch (error) {
      console.log(error);
      // onFail(error);
    }
    set({ loading: false });
  },
}));
