import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ListPinIcon } from "../../icons";

const typeMess = {
  TEXT: "text",
  IMAGE: "image",
  FILE: "file",
  VIDEO: "video",
};

export default function RoomChatPin() {
  const roomSelected = useSelector((state) => state.chat.roomSelected);
  const { room_chat_pin } = roomSelected;
  const [isShowAll, setShowAll] = useState(false);

  const renderContentMess = (message) => {
    switch (message?.type) {
      case "image":
        return "Hình ảnh";
      case "custom":
        return "Tệp đính kèm";
      case "video":
        return "Video";
      default:
        return message?.content;
    }
  };
  return (
    <>
      <div className="message-list">
        {room_chat_pin.map((item, index) => {
          if (!isShowAll && index > 0) return null;
          const { message, user } = item;
          return (
            <div key={index} className="message">
              <ListPinIcon style={{ width: "20px", height: "20px" }} />
              <div className="message__content">
                <p style={{ fontWeight: "600", color: "#0068ff" }}>
                  Danh sách ghim({room_chat_pin.length})
                </p>
                <p style={{ marginTop: "2px" }}>{renderContentMess(message)}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
