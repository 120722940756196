import { callApi } from "../apis";

const getProfile = () => {
  return callApi(`/profile`, "get");
};

const getBadge = () => {
  return callApi(`/franchise/customer/badge`, "get");
};

export const dataApp = {
  getProfile,
  getBadge,
};
