import { io } from 'socket.io-client';
import { v4 as uuidv4 } from "uuid";

function getDeviceId() { 
   var deviceId = localStorage.getItem("deviceId");
    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem("deviceId", deviceId);
    } 
    console.log("deviceId", deviceId);
    return deviceId;
}

const URL = `https://api.comnieuthienly.vn:6442?device-id=${getDeviceId()}`;

export const socket = io(URL, {
    autoConnect: false,
    reconnectionDelay: 2000,
    reconnectionDelayMax: 5000,
  });
