import { callApi } from "../apis"

const getFile = (param) => {
  return callApi(`/franchise/customer/file-franchise${param}`, "get");
};

const getFileCate = (form) => {
  return callApi(`/franchise/categories`, "get");
};

const getFileDetail = (id) => {
  return callApi(`/franchise/file-franchise/${id}`, "get");
};

const addFileFranchise = (form) => {
  return callApi(`/franchise/file-franchise`, "post", form);
};

export const file = {
  getFile,
  getFileCate,
  getFileDetail,
  addFileFranchise,
};
