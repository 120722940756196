import PropTypes from "prop-types";

export default function VoucherIcon({ className, ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest}
    >
      <path
        d="M17.0571 12.5752C14.6491 12.6328 14.6451 16.1624 17.0523 16.2264C19.4603 16.168 19.4635 12.64 17.0571 12.5752ZM16.4291 14.4032C16.4299 13.584 17.6755 13.5808 17.6803 14.4C17.6771 15.2168 16.4355 15.22 16.4291 14.4032Z"
        fill="currentColor"
      />
      <path
        d="M12.2561 11.4248C14.6633 11.364 14.6633 7.83439 12.2561 7.77359C9.84808 7.83519 9.84968 11.3624 12.2561 11.4248ZM12.2561 8.97439C13.0745 8.97759 13.0745 10.2224 12.2561 10.2256C11.4377 10.2216 11.4385 8.97919 12.2561 8.97439Z"
        fill="currentColor"
      />
      <path
        d="M18.5525 8.1104C18.3181 7.8752 17.9365 7.8744 17.7013 8.1088L10.7493 15.028C10.5101 15.2576 10.5029 15.6376 10.7325 15.876C10.9669 16.1208 11.3597 16.1216 11.5957 15.8784L18.5485 8.96C18.7845 8.7264 18.7861 8.3464 18.5525 8.1104Z"
        fill="currentColor"
      />
      <path
        d="M7.08193 8.4296C6.75073 8.4296 6.48193 8.6984 6.48193 9.0296V10.568C6.48193 10.8992 6.75073 11.168 7.08193 11.168C7.41313 11.168 7.68193 10.8992 7.68193 10.568V9.0296C7.68193 8.6984 7.41313 8.4296 7.08193 8.4296Z"
        fill="currentColor"
      />
      <path
        d="M7.08193 12.8328C6.75073 12.8328 6.48193 13.1016 6.48193 13.4328V14.9704C6.48193 15.3016 6.75073 15.5704 7.08193 15.5704C7.41313 15.5704 7.68193 15.3016 7.68193 14.9704V13.4328C7.68193 13.1016 7.41313 12.8328 7.08193 12.8328Z"
        fill="currentColor"
      />
      <path
        d="M22.7034 10.0176C23.381 9.6544 23.8026 8.9464 23.8002 8.1776V6.12C23.7986 4.964 22.8626 4.028 21.7066 4.0264H2.29381C1.13781 4.028 0.201813 4.964 0.200213 6.12V8.176C0.197813 8.9448 0.619413 9.6528 1.29701 10.016C2.39221 10.6024 2.80501 11.9656 2.21861 13.0608C2.00901 13.452 1.68821 13.7728 1.29701 13.9824C0.618614 14.3464 0.197013 15.0544 0.200213 15.824V17.88C0.201813 19.036 1.13781 19.972 2.29381 19.9736H21.7066C22.8626 19.972 23.7986 19.036 23.8002 17.88V15.824C23.8026 15.0552 23.381 14.3472 22.7034 13.984C21.1322 13.1976 21.1442 10.804 22.7034 10.0176ZM20.7274 13.6272C21.0482 14.2264 21.5394 14.7176 22.1386 15.0384C22.4258 15.1936 22.6034 15.4944 22.6002 15.8208V17.8768C22.5994 18.3704 22.2002 18.7696 21.7066 18.7704H7.68021C7.64741 18.2504 7.88661 17.2552 7.08021 17.2352C6.27301 17.2544 6.51301 18.2544 6.48021 18.7736H2.29381C1.80021 18.7728 1.40101 18.3736 1.40021 17.88V15.824C1.39701 15.4976 1.57461 15.1968 1.86181 15.0416C3.54101 14.1424 4.17221 12.052 3.27301 10.3728C2.95221 9.7736 2.46101 9.2824 1.86181 8.9616C1.57461 8.8064 1.39701 8.5056 1.40021 8.1792V6.12C1.40101 5.6264 1.80021 5.2272 2.29381 5.2264H6.48021C6.51301 5.7472 6.27221 6.7448 7.08021 6.7648C7.88741 6.7456 7.64741 5.7456 7.68021 5.2264H21.7066C22.2002 5.2272 22.5994 5.6264 22.6002 6.12V8.176C22.6034 8.5024 22.4258 8.8032 22.1386 8.9584C20.4594 9.8576 19.8274 11.948 20.7274 13.6272Z"
        fill="currentColor"
      />
    </svg>
  );
}

VoucherIcon.propTypes = {
  className: PropTypes.string,
};
