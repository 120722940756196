import { callApi } from "../apis"

const getPost = (form) => {
  return callApi(`/franchise/post`, "get", form);
};

const getPostDetail = (id) => {
  return callApi(`/franchise/post/${id}`, "get");
};

export const post = {
  getPost,
  getPostDetail,
};
