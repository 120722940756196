import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "./header";
import { StyledMainContent } from "./MainContent.style";
import RoomChat from "../Common/RoomChat";
import { Drawer } from "antd";
import RoomInfo from "./roomInfo";

export default function MainContent() {
  const roomSelected = useSelector((state) => state.chat.roomSelected);
  const [OpenDrawerPin, setOpenDrawerPin] = useState(false);
  const [OpenDrawerInfo, setOpenDrawerInfo] = useState(false);
  const [openDrawerSearchMess, setOpenDrawerSearchMess] = useState(false);

  useEffect(() => {
    if (roomSelected) {
      setOpenDrawerInfo(false);
    }
  }, [roomSelected?.id]);

  if (!roomSelected?.id)
    return (
      <div
        className="flex justify-center items-center"
        style={{ flex: "1", color: "#0068ff", fontSize: "18px" }}
      >
        Chọn một cuộc hội thoại để gửi tin nhắn!
      </div>
    );
  return (
    <StyledMainContent>
      <Header
        setOpenDrawerPin={setOpenDrawerPin}
        setOpenDrawerInfo={setOpenDrawerInfo}
        openDrawerSearchMess={openDrawerSearchMess}
        setOpenDrawerSearchMess={setOpenDrawerSearchMess}
      />
      <RoomChat
        setOpenDrawerPin={setOpenDrawerPin}
        openDrawerSearchMess={openDrawerSearchMess}
        setOpenDrawerSearchMess={setOpenDrawerSearchMess}
        OpenDrawerPin={OpenDrawerPin}
      />
      {OpenDrawerInfo && (
        <Drawer
          title="Thông tin hội thoại"
          placement="right"
          closable={false}
          onClose={() => setOpenDrawerInfo(false)}
          open={OpenDrawerInfo}
          getContainer={false}
          bodyStyle={{ padding: "0" }}
        >
          <RoomInfo setOpenDrawerInfo={setOpenDrawerInfo}/>
        </Drawer>
      )}
    </StyledMainContent>
  );
}
