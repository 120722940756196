import React from "react";
import { useEffect, useState } from "react";
import { Modal, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Select,
  Switch,
  Checkbox,
  DatePicker,
  ConfigProvider,
  InputNumber,
} from "antd";
import viVN from "antd/locale/vi_VN";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { RepositoryRemote } from "../../services";

const AddCustomerModal = ({ open, closeModal, onOk, id }) => {
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (id != null) {
      getProfile(id);
    } else {
      onReset();
    }
  }, [open]);

  const getProfile = async (id) => {
    setLoading(true);
    try {
      const response = await RepositoryRemote.auth.getProfile(id);
      onFill(response.data.data);

      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  
  const onFill = (dataInput) => {
    form.setFieldsValue({
      ...dataInput,
      date_range:
        dataInput?.date_range == null ? null : dayjs(dataInput?.date_range),
      birthday: dataInput?.birthday == null ? null : dayjs(dataInput?.birthday),
      date_range_license:
        dataInput?.franchise_info?.date_range_license == null
          ? null
          : dayjs(dataInput?.franchise_info?.date_range_license),
      date_range_legal_person:
        dataInput?.franchise_info?.date_range_legal_person == null
          ? null
          : dayjs(dataInput?.franchise_info?.date_range_legal_person),
      nationality: dataInput?.franchise_info?.nationality,
      fax: dataInput?.franchise_info?.fax,
      website: dataInput?.franchise_info?.website,
      work_place: dataInput?.franchise_info?.work_place,
      is_company: dataInput?.franchise_info?.is_company,
      business_license: dataInput?.franchise_info?.business_license,
      issue_by_license: dataInput?.franchise_info?.issue_by_license,
      income: dataInput?.franchise_info?.income,
      permanent_address_legal_person:
        dataInput?.franchise_info?.permanent_address_legal_person,
      temporary_address_legal_person:
        dataInput?.franchise_info?.temporary_address_legal_person,
      company_phone: dataInput?.franchise_info?.company_phone,
      company_fax: dataInput?.franchise_info?.company_fax,
      company_email: dataInput?.franchise_info?.company_email,
      company_website: dataInput?.franchise_info?.company_website,
      latest_profit: dataInput?.franchise_info?.latest_profit,
      company_name: dataInput?.franchise_info?.company_name,
      business_license_company:
        dataInput?.franchise_info?.business_license_company,
      issue_by_license_company:
        dataInput?.franchise_info?.issue_by_license_company,
      company_address: dataInput?.franchise_info?.company_address,
      business_type: dataInput?.franchise_info?.business_type,
      legal_person: dataInput?.franchise_info?.legal_person,
      cmnd_legal_person: dataInput?.franchise_info?.cmnd_legal_person,
      issue_by_legal_person: dataInput?.franchise_info?.issue_by_legal_person,
      restaurants: dataInput?.franchise_info?.restaurants,
      name_manager: dataInput?.franchise_info?.name_manager,
      phone_number_manager: dataInput?.franchise_info?.phone_number_manager,
      email_manager: dataInput?.franchise_info?.email_manager,
    });
    console.log(form.getFieldValue("birthday"));
    setReset(!reset);
    console.log(form.getFieldValue("is_company"));
  };

  // const addCustomer = async (data) => {
  //   setLoading(true);
  //   try {
  //     const response = await franchiseServices.addCustomer(data);
  //     onOk();
  //     onReset();
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setLoading(false);
  // };

  // const updateCustomer = async (data, id) => {
  //   setLoading(true);
  //   try {
  //     const response = await franchiseServices.updateCustomer(data, id);
  //     onOk();
  //     onReset();
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setLoading(false);
  // };

  const onFinish = (values) => {
    console.log(values);
    // if (dataInput != null) {
    //   updateCustomer(
    //     {
    //       ...values,
    //       birthday: values.birthday?.format("YYYY-MM-DD"),
    //       date_range: values.date_range?.format("YYYY-MM-DD"),
    //       date_range_license: values.date_range_license?.format("YYYY-MM-DD"),
    //       date_range_legal_person:
    //         values.date_range_legal_person?.format("YYYY-MM-DD"),
    //     },
    //     dataInput.id
    //   );
    // } else {
    //   addCustomer({
    //     ...values,
    //     birthday: values.birthday?.format("YYYY-MM-DD"),
    //     date_range: values.date_range?.format("YYYY-MM-DD"),
    //     date_range_license: values.date_range_license?.format("YYYY-MM-DD"),
    //     date_range_legal_person:
    //       values.date_range_legal_person?.format("YYYY-MM-DD"),
    //   });
    // }
  };

  const onReset = () => {
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 24, // Label chiếm 50% chiều rộng
    },
    wrapperCol: {
      span: 24, // Input chiếm 50% chiều rộng
    },
  };

  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please select time!",
      },
    ],
  };

  const { Option } = Select;

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const beforeUpload = (file) => {
    // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    // if (!isJpgOrPng) {
    //   toast.error("You can only upload JPG/PNG file!");
    //   return false;
    // }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      toast.error("File phải nhỏ hơn 20MB!");
      return false;
    }
    return isLt5M;
  };

  const [fileList, setFileList] = useState([]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    console.log(info.file.status);

    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
      });
      setFileList([...fileList, info.file]);
    }
  };

  return (
    <Modal
      title={
        "Thông tin khách hàng"
      }
      centered
      open={open}
      width={1500}
      style={{ top: 10 }}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4 overflow-y-auto h-auto max-h-79vh"
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          disabled={true}
        
          className="grid grid-cols-12 gap-4 w-99.9%"
        >
          <p
            className="col-span-12 mb-2 font-semibold text-blue-500"
          >
            Thông tin cơ bản:
          </p>
          <Form.Item
            name="name"
            label="Họ và tên"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập họ và tên"} />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="Số điện thoại"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập số điện thoại"} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            className="col-span-3"
            rules={[
              {
                required: false,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập email"} />
          </Form.Item>
          {/* {(
            <Form.Item
              name="password"
              label="Mật khẩu tài khoản"
              className="col-span-3"
              rules={[
                {
                  required: id == null ? true : false,
                  message: "Không được để trống !",
                },
              ]}
            >
              <Input placeholder={"Nhập mật khẩu"} />
            </Form.Item>
          )} */}
          <Form.Item
            name="cmnd"
            label="Số CCCD/Passport:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập CCCD/Passport"} />
          </Form.Item>
          <Form.Item
            name="birthday"
            label="Sinh ngày:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <ConfigProvider locale={viVN}>
              {reset != null && (
                <DatePicker
                  value={form.getFieldValue("birthday")}
                  onChange={(value) => {
                    form.setFieldValue("birthday", value);
                    setReset(!reset);
                  }}
                />
              )}
            </ConfigProvider>
          </Form.Item>
          <Form.Item
            name="nationality"
            label="Quốc tịch:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập quốc tịch"} />
          </Form.Item>
          <Form.Item
            name="issued_by"
            label="Nơi cấp:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập nơi cấp"} />
          </Form.Item>
          <Form.Item
            name="date_range"
            label="Ngày cấp:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <ConfigProvider locale={viVN}>
              {reset != null && (
                <DatePicker
                  value={form.getFieldValue("date_range")}
                  onChange={(value) => {
                    form.setFieldValue("date_range", value);
                    setReset(!reset);
                  }}
                />
              )}
            </ConfigProvider>
          </Form.Item>
          <Form.Item
            name="permanent_address"
            label="Hộ khẩu thường trú:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập hộ khẩu thường trú"} />
          </Form.Item>
          <Form.Item
            name="temporary_address"
            label="Nơi ở hiện nay:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập nơi ở hiện nay"} />
          </Form.Item>
          <Form.Item
            name="fax"
            label="Fax:"
            className="col-span-3"
            rules={[
              {
                required: false,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập Fax:"} />
          </Form.Item>
          <Form.Item
            name="website"
            label="Website:"
            className="col-span-3"
            rules={[
              {
                required: false,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập Website"} />
          </Form.Item>
          <Form.Item
            name="married"
            label="Tình trạng hôn nhân:"
            className="col-span-3"
            rules={[
              {
                required: false,
                message: "Không được để trống !",
              },
            ]}
          >
            <Checkbox
              defaultChecked={form.getFieldValue("married")}
              style={{
                lineHeight: "32px",
              }}
              onChange={(v) => {
                form.setFieldValue("married", v.target.checked);
              }}
            >
              Tình trạng hôn nhân
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="work_place"
            label="Địa chỉ đơn vị kinh doanh hiện tại hoặc nơi làm việc:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input
              placeholder={
                "Nhập địa chỉ đơn vị kinh doanh hiện tại hoặc nơi làm việc"
              }
            />
          </Form.Item>
          <Form.Item
            name="business_license"
            label="Giấy phép kinh doanh hộ cá thể số:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập giấy phép kinh doanh hộ cá thể số"} />
          </Form.Item>
          <Form.Item
            name="issue_by_license"
            label="Nơi cấp giấy phép:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập nơi cấp giấy phép"} />
          </Form.Item>
          <Form.Item
            name="income"
            label="Thu nhập bình quân tháng:"
            className="col-span-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <InputNumber
              className="col-span-12"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              placeholder={"Nhập thu nhập bình quân tháng"}
            />
          </Form.Item>
          <Form.Item
            name="is_company"
            label=""
            className="col-span-12"
            rules={[
              {
                required: false,
                message: "Không được để trống !",
              },
            ]}
          >
            <div className="flex flex-row">
              <p className="mr-2" style={{ fontWeight: "500", color: "blue" }}>
                Khách hàng là doanh nghiệp:
              </p>
              <Switch
                checked={form.getFieldValue("is_company")}
                onChange={(v) => {
                  form.setFieldValue("is_company", v);
                  setReset(!reset);
                }}
              />
            </div>
          </Form.Item>
          {form.getFieldValue("is_company") == true && reset != null ? (
            <>
              <Form.Item
                name="company_name"
                label="Tên doanh nghiệp:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập tên doanh nghiệp"} />
              </Form.Item>
              <Form.Item
                name="business_license_company"
                label="Giấy ĐKKD số:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập giấy ĐKKD số"} />
              </Form.Item>
              <Form.Item
                name="issue_by_license_company"
                label="Nơi cấp giấy phép:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập nơi cấp giấy phép"} />
              </Form.Item>
              <Form.Item
                name="date_range_license"
                label="Ngày cấp giấy phép:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <ConfigProvider locale={viVN}>
                  {reset != null && (
                    <DatePicker
                      value={form.getFieldValue("date_range_license")}
                      onChange={(value) => {
                        form.setFieldValue("date_range_license", value);
                        setReset(!reset);
                      }}
                    />
                  )}
                </ConfigProvider>
              </Form.Item>
              <Form.Item
                name="company_address"
                label="Địa chỉ trụ sở chính:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập địa chỉ trụ sở chính"} />
              </Form.Item>
              <Form.Item
                name="business_type"
                label="Ngành nghề kinh doanh chủ yếu:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập ngành nghề kinh doanh chủ yếu"} />
              </Form.Item>
              <Form.Item
                name="legal_person"
                label="Tên người đại diện theo pháp luật:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập tên người đại diện theo pháp luật"} />
              </Form.Item>
              <Form.Item
                name="cmnd_legal_person"
                label="CCCD/Passport đại diện:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập CCCD/Passport đại diện"} />
              </Form.Item>
              <Form.Item
                name="issue_by_legal_person"
                label="Nơi cấp CCCD/Passport của đại diện:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input
                  placeholder={"Nhập nơi cấp CCCD/Passport của đại diện"}
                />
              </Form.Item>
              <Form.Item
                name="date_range_legal_person"
                label="Ngày cấp CCCD/Passport của đại diện:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <ConfigProvider locale={viVN}>
                  {reset != null && (
                    <DatePicker
                      value={form.getFieldValue("date_range_legal_person")}
                      onChange={(value) => {
                        form.setFieldValue("date_range_legal_person", value);
                        setReset(!reset);
                      }}
                    />
                  )}
                </ConfigProvider>
              </Form.Item>
              <Form.Item
                name="permanent_address_legal_person"
                label="HKTT của đại diện:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập HKTT của đại diện"} />
              </Form.Item>
              <Form.Item
                name="temporary_address_legal_person"
                label="Nơi ở hiện nay của đại diện:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập nơi ở hiện nay của đại diện"} />
              </Form.Item>
              <Form.Item
                name="company_phone"
                label="Số điện thoại liên lạc tại doanh nghiệp:"
                className="col-span-3"
                rules={[
                  {
                    required: true,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input
                  placeholder={"Nhập số điện thoại liên lạc tại doanh nghiệp"}
                />
              </Form.Item>
              <Form.Item
                name="company_fax"
                label="Fax doanh nghiệp:"
                className="col-span-3"
                rules={[
                  {
                    required: false,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập fax doanh nghiệp"} />
              </Form.Item>
              <Form.Item
                name="company_email"
                label="Email doanh nghiệp:"
                className="col-span-3"
                rules={[
                  {
                    required: false,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập email doanh nghiệp"} />
              </Form.Item>
              <Form.Item
                name="company_website"
                label="Website doanh nghiệp:"
                className="col-span-3"
                rules={[
                  {
                    required: false,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <Input placeholder={"Nhập Website doanh nghiệp"} />
              </Form.Item>
              <Form.Item
                name="latest_profit"
                label="Lợi nhuận sau thuế của doanh nghiệp theo BCTC của năm gần nhất (nếu có):"
                className="col-span-3"
                rules={[
                  {
                    required: false,
                    message: "Không được để trống !",
                  },
                ]}
              >
                <InputNumber
                  className="col-12"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  placeholder={
                    "Nhập lợi nhuận sau thuế của doanh nghiệp theo BCTC của năm gần nhất (nếu có) "
                  }
                />
              </Form.Item>
            </>
          ) : (
            <></>
          )}
          <p
            className="col-span-12 mb-2 font-semibold text-blue-500"
            
          >
            Thông tin nhà hàng nhượng quyền:
          </p>
          <div className="col-span-12 mb-2">
            <Form.List name="restaurants">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "franchise_info_id"]}
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Input placeholder="Mã nhà hàng" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Input placeholder="Tên nhà hàng" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "address"]}
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Input placeholder="Địa chỉ nhà hàng" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "area"]}
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Input placeholder="Khu vực" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "opening_day"]}
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Input placeholder="Ngày khai trương" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "phone_number"]}
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Input placeholder="Số điện thoại" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "email"]}
                        rules={[
                          {
                            required: false,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Input placeholder="Địa chỉ email nhà hàng" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm nhà hàng
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <p
            className="col-span-12 mb-2 font-semibold text-blue-500"
            
          >
            Thông tin quản lý phụ trách:
          </p>

          <Form.Item
              name="name_manager"
              label="Tên quản lý:"
              className="col-span-3"
              rules={[
                {
                  required: false,
                  message: "Không được để trống !",
                },
              ]}
            >
              <Input placeholder={"Nhập tên quản lý"} />
            </Form.Item>
            <Form.Item
              name="email_manager"
              label="Địa chỉ email:"
              className="col-span-3"
              rules={[
                {
                  required: false,
                  message: "Không được để trống !",
                },
              ]}
            >
              <Input placeholder={"Nhập địa chỉ email"} />
            </Form.Item>
            <Form.Item
              name="phone_number_manager"
              label="Số điện thoại quản lý:"
              className="col-span-3"
              rules={[
                {
                  required: false,
                  message: "Không được để trống !",
                },
              ]}
            >
              <Input placeholder={"Nhập số điện thoại quản lý"} />
            </Form.Item>

          {/* <Form.Item className="col-span-12 d-flex justify-content-end">
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item> */}
        </Form>
      </div>
    </Modal>
  );
};

export default AddCustomerModal;
