import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Dropdown, Row, Space } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthStore } from "../../store/authStore";
import { StyledHeader } from "./Index.style";
import { dataAppStore } from "../../store/dataAppStore";
import AddCustomerModal from "./AddCustomerModal";

const Header = ({ collapsed, changeCollapsed }) => {
  const { logOut } = useAuthStore((state) => state);

  const { textTitle, profile, resetProfile } = dataAppStore((state) => state);

  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, visible: false });
  };

  const handleLogout = () => {
    resetProfile();
    logOut();
  };
  const items = [
    {
      key: "link-to-profile",
      label: (
        <Button
          onClick={(e) => {
            setModal({
              ...modal,
              method: "ADD",
              id: profile.id,
              visible: true,
            });
          }}
        >
          Tài khoản
        </Button>
      ),
    },
    {
      key: "Đăng xuất",
      label: (
        <Link to="/login" onClick={handleLogout}>
          Đăng xuất
        </Link>
      ),
    },
  ];

  return (
    <StyledHeader>
      <Row justify="space-between">
        <Col>
          <Row>
            <Space>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  onClick: () => changeCollapsed(),
                }
              )}
              <span className="font-medium text-[20px] text-[#092498]">
                {textTitle}
              </span>
            </Space>
          </Row>
        </Col>
        <Col>
          <Row justify="center">
            <Col>
              <Dropdown menu={{ items }} placement="bottomRight" arrow>
                <Button type="text">
                  <Avatar
                    size="large"
                    src={profile?.avatar}
                    style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                  ></Avatar>
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      <AddCustomerModal
        open={modal.visible && modal.method === "ADD"}
        id={modal.id}
        onOk={() => {
          // getCustomerFranchise(1);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </StyledHeader>
  );
};

export default Header;

Header.propTypes = {
  collapsed: PropTypes.bool,
  changeCollapsed: PropTypes.func.isRequired,
};
