import { create } from "zustand";
import { RepositoryRemote } from "../services";
import { removeToken } from "../utils/auth";

export const dataAppStore = create((set) => ({
  tokenInfo: {},
  profile: {},
  loading: false,
  textTitle: "Tổng quan",
  badge: {},
  getProfile: async (onSuccess = () => {}, onFail = () => {}) => {
    try {
      set({ loading: true });
      const response = await RepositoryRemote.dataApp.getProfile();
      console.log(response.data?.data);
      set({ profile: response.data?.data });
      onSuccess(response.data?.data);
    } catch (error) {
      onFail(error.response.data.msg || "Có lỗi xảy ra!");
    }
    set({ loading: false });
  },
  getBadge: async (onSuccess = () => {}, onFail = () => {}) => {
    try {
      set({ loading: true });
      const response = await RepositoryRemote.dataApp.getBadge();
      // console.log(response.data?.data);
      set({ badge: response.data?.data });
      onSuccess(response.data?.data);
    } catch (error) {
      onFail(error.response.data.msg || "Có lỗi xảy ra!");
    }
    set({ loading: false });
  },
  setTitle(title) {
    set({ textTitle: title });
  },
  resetProfile() {
    set({ profile: null });
  },
}));
