import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Input, Row, Form } from "antd";
import { PATH } from "../../constants/paths";
import { useEffect, useState } from "react";
import { validatePassword, validatePhoneNumber } from "../../utils/validate";
import LOGO from "../../assets/images/logo-header.png";
import { useAuthStore } from "../../store/authStore";
import { alerts } from "../../utils/alerts";

export default function InputNumber() {
  const { checkExists } = useAuthStore((state) => state);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  useEffect(() => {}, []);

  const checkExist = (value) => {
    const onSuccess = (token) => {
        navigate(PATH.FORGOT_PASSWORD)
    };
    const onFail = (error) => { 
       alerts.error(error?.response?.data?.msg);
    };

    checkExists(value, onSuccess, onFail);
  };

  const onFinish = (values) => {
    console.log(values);
    checkExist(values);
  };

  const tailLayout = {
    wrapperCol: {
      offset: 20,
      span: 28,
    },
  };

  return (
    <div className="w-[500px] mx-auto border-[1px] border-[#ccc] border-solid p-5 rounded-[6px] mt-[25vh]">
      <Row>
        <Col flex={1}>
          <img src={LOGO} className="w-full h-full" alt="logo"></img>
        </Col>
        <Col flex={1}>
          <Form form={form} name="control-hooks" onFinish={onFinish}>
            <Form.Item
              name="phone_number"
              label="Số điện thoại"
              rules={[
                {
                  required: true,
                  message: "Không được để trống !",
                },
              ]}
            >
              <Input placeholder={"Nhập số điện thoại"} />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Tiếp tục
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
