import { callApi } from "../apis"

const getMails = (param) => {
  return callApi(`/franchise/customer/mails${param}`, "get");
};

const getMailDetail = (id) => {
  return callApi(`/franchise/mail/${id}`, "get");
};

const addMail = ( form) => {
  return callApi(`/franchise/mail`, "post", form,);
};

const getMailMess = (param) => {
  return callApi(`/franchise/mail/mess${param}`, "get");
};


const getMailMessDetail = (id) => {
  return callApi(`/franchise/mail/mess/${id}`, "get");
};


const addMailMess = (form) => {
  return callApi(`/franchise/mail/mess`, "post", form,);
};


export const mail = {
  getMails,
  getMailDetail,
  addMail,
  getMailMess,
  addMailMess,
  getMailMessDetail,
};
