import { auth } from "./auth";
import { post } from "./post";
import { help } from "./help";
import { report } from "./report";
import { mail } from "./mail";
import { file } from "./file";
import { meeting } from "./meeting";
import { contact } from "./contact";
import { dataApp } from "./dataApp";

export const RepositoryRemote = {
  auth,
  post,
  help,
  report,
  mail,
  file,
  meeting,
  contact,
  dataApp,
};
