import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./components/loading/Index";
import { PATH } from "./constants/paths";
import MainLayout from "./layouts/mainLayout/MainLayout";
import ChatHome from "./pages/Chat";
import ForgotPassword from "./pages/login/ForgotPassword";
import InputNumber from "./pages/login/InputNumber";
import Login from "./pages/login/index";
import { dataAppStore } from "./store/dataAppStore";
import { getToken } from "./utils/auth";
import { socket } from "./pages/Chat/socket/socket.js";
import { useDispatch } from "react-redux";

// const Sellers = lazy(() => import('./pages/sellers/Index'));
const Home = lazy(() => import("./pages/home/Index.jsx"));
const ReportsPage = lazy(() => import("./pages/reports/ReportsPage"));
const FranchiseMailPage = lazy(() => import("./pages/mails/FranchiseMailPage"));
const FilesPage = lazy(() => import("./pages/files/FilesPage"));
const MeetingsPage = lazy(() => import("./pages/meeting/MeetingsPage"));
const PostPage = lazy(() => import("./pages/post/PostPage"));
const HelpPage = lazy(() => import("./pages/help/HelpPage"));
const ContactPage = lazy(() => import("./pages/contact/ContactPage"));
// const Stores = lazy(() => import('./pages/stores/index'));
// const Vouchers = lazy(() => import('./pages/vouchers'));
// const Products = lazy(() => import('./pages/products'));
// const Customers = lazy(() => import('./pages/customers'));
// const Categories = lazy(() => import('./pages/categories'));
// const Shipments = lazy(() => import('./pages/shipments'));
// const MethodPayments = lazy(() => import('./pages/methodPayment'));
// const HomepageInterface = lazy(() =>
//   import('./pages/settings/homepageInterface')
// );
// const IdentityRequest = lazy(() => import('./pages/identityRequest/Index'));
// const OrderScreen = lazy(() => import('./pages/orders/Index'));
// const OrderDetail = lazy(() => import('./pages/orders/OrderDetail'));

const PrivateRoute = () => {
  // const { getAllBadges } = useBadgesStore();
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const { getProfile, getBadge } = dataAppStore((state) => state);


  useEffect(() => {
    const checkAuthentication = async () => {
      const token = await getToken();
      setAuthenticated(token !== null);
      setLoading(false);
      if (token !== null) {
        getProfile();
        getBadge();
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    socket.connect();
    socket.on("connect", () => {
      console.log("socket connected");
    });
    socket.on("disconnect", () => {
      console.log("socket has been disconnected...");
    });
  }, []);

  if (loading) {
    return null;
  }

  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<MainLayout />}>
              <Route
                path={PATH.HOME}
                element={
                  <Suspense fallback={<Loading />}>
                    <Home />
                  </Suspense>
                }
              />
              <Route
                path="/reports"
                element={
                  <Suspense fallback={<Loading />}>
                    <ReportsPage />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/mails"
                element={
                  <Suspense fallback={<Loading />}>
                    <FranchiseMailPage />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/files"
                element={
                  <Suspense fallback={<Loading />}>
                    <FilesPage />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/meetings"
                element={
                  <Suspense fallback={<Loading />}>
                    <MeetingsPage />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/posts"
                element={
                  <Suspense fallback={<Loading />}>
                    <PostPage />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/helps"
                element={
                  <Suspense fallback={<Loading />}>
                    <HelpPage />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/contacts"
                element={
                  <Suspense fallback={<Loading />}>
                    <ContactPage />
                  </Suspense>
                }
              ></Route>
            </Route>
          </Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/input-phonenumber" element={<InputNumber />}></Route>
        </Routes>
      </BrowserRouter>
      <ChatHome />
      <ToastContainer></ToastContainer>
    </React.Fragment>
  );
}
export default App;
