// import { getMeta, store_code } from "../ut: ",

export const constants = {
  DEFAULT_TITLE:
    "Xây dựng hệ thống bán hàng mạnh mẽ, mang thương hiệu của riêng bạn.",
  DEFAULT_HOME_TITLE: "Trang chủ",
  DEFAULT_ADDRESS: "Số 41, Ngõ 76, Phố Mai Dịch, Cầu Giấy, Hà Nội.",
  DEFAULT_PRODUCT_IMG: "/img/default_product.jpg",
  DEFAULT_SHORTCUT: "/img/default_shortcut.png",
  DEFAULT_HEADER_BACKGROUND_COLOR: "white",
  DEFAULT_HEADER_TEXT_COLOR: "#757575",
  DEFAULT_LOGO: "",
  DEFAULT_MAIL: "ikitech.vn@gmail.com",
  MAIN_PAGE_URL: "https://ikitech.vn/",
  DEFAULT_PHONE: "0246.0278.753",
  DEFAULT_COLOR: "#e62e04",
  LOADING_WHEN_SUBMIT_REGISTER: "LOADING_WHEN_SUBMIT_REGISTER",
  //STATUS
  // API_URL: getMeta("domain_api") + "/api",
  API_URL: "https://api.comnieuthienly.vn" + "/api",
  // STORE_CODE: store_code,

  // CHAT
  SHOW_LOADING_DRAWER: "SHOW_LOADING_DRAWER",
  RESET_ROOM_CHAT_STATUS: "RESET_ROOM_CHAT_STATUS",
  GET_ROOM_CHAT_SUCCESS: "GET_ROOM_CHAT_SUCCESS",
  GET_ROOM_CHAT_FAILURE: "GET_ROOM_CHAT_FAILURE",
  SELECTED_ROOM_CHAT: "SELECTED_ROOM_CHAT",
  RECEIVE_MESSAGE: "RECEIVE_MESSAGE",
  CREATE_MESSAGE: "CREATE_MESSAGE",
  LOADING_FILE: "LOADING_FILE",
  CHANGE_ROOM_CHAT: "CHANGE_ROOM_CHAT",
  SELECTED_REPLY_MESSAGE: "SELECTED_REPLY_MESSAGE",
  UPDATE_MESSAGE: "UPDATE_MESSAGE",
  ADD_PIN_MESSAGE: "ADD_PIN_MESSAGE",
  GET_MESSAGE_LIST: "GET_MESSAGE_LIST",
  TOGGLE_MODAL_SELECTS_USER: "TOGGLE_MODAL_SELECTS_USER",
  SEARCH_MEMBERS: "SEARCH_MEMBERS",
  REDIRECT_MESSAGE: "REDIRECT_MESSAGE",
  GET_FILE_LIST: "GET_FILE_LIST",
  UPDATE_ROOM_CHAT: "UPDATE_ROOM_CHAT",
  UPDATE_MEMBER_ROOM_CHAT: "UPDATE_MEMBER_ROOM_CHAT",
  CREATE_ROOM_CHAT: "CREATE_ROOM_CHAT",
  DELETE_ROOM_CHAT: "DELETE_ROOM_CHAT",
  SHOW_LOADING_MODAL: "SHOW_LOADING_MODAL",
  GET_ONE_ROOM_CHAT: "GET_ONE_ROOM_CHAT",
  TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
  GET_ROOM_CHAT_BY_USER_ID: "GET_ROOM_CHAT_BY_USER_ID",
  SAVE_USER_ID: "SAVE_USER_ID",
  TOGGLE_HIDE_MAIN_CONTENT: "TOGGLE_HIDE_MAIN_CONTENT",
  TOGGLE_MODAL_USER_INFO: "TOGGLE_MODAL_USER_INFO",
};

export const statusIdentity = {
  PROGRESSING: 0,
  UNAPPROVED: 1,
  APPROVED: 2,
};

export const stepIdentityStatus = {
  PROGRESSING: 0,
  UNAPPROVED: 1,
  APPROVED: 2,
  INITIAL_VALUE: 3,
};

export const statusProduct = {
  PROGRESSING: 0,
  VIOLATION: 1,
  APPROVED: 2,
  UNAPPROVED: 3,
  DELETED: 4,
};

export const LETTERS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];
