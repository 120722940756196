import { callApi } from "../apis"

const login = (form) => {
  return callApi(`/login`, "post", form);
};
const register = (form) => {
  return callApi(`/register`, "post", form);
};
const checkExists = (form) => {
  return callApi(`/check_phone_email`, "post", form);
};
const resetPassword = (form) => {
  return callApi(`/reset-password`, "post", form);
};
const sendOtp = (form) => {
  return callApi(`/personnel/staff/sent_otp`, "post", form);
};
const sendEmailOtp = (form) => {
  return callApi(`/send_email_otp`, "post", form);
};

const getProfile = (id) => {
  return callApi(`/franchise/customers/${id}`, "get");
};



export const auth = {
  login,
  register,
  sendOtp,
  checkExists,
  resetPassword,
  sendEmailOtp,
  getProfile,
};
