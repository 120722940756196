import { Link, useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/logo-header.png";
import { Button, Form, Input } from "antd";
import { PATH } from "../../constants/paths";
import { useEffect, useState } from "react";
import { validatePassword, validatePhoneNumber } from "../../utils/validate";
import OTPInput from "react-otp-input";
import { useAuthStore } from "../../store/authStore";
import { alerts } from "../../utils/alerts";

export default function ForgotPassword() {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const navigate = useNavigate();
  const [resendEnabled, setResendEnabled] = useState(false);
  const { phone_number, resetPassword, sendOtp } = useAuthStore(
    (state) => state
  );
  const [loading, setLoading] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  useEffect(() => {
    if (hasMounted) {
      const sendOtpSms = async (value) => {
        const onSuccess = (token) => {
          alerts.success("Đã gửi mã");
          setOtp(""); // Đặt giá trị OTP thành rỗng khi gửi thành công
          setTimer(30); // Đặt lại giá trị của timer
        };
        const onFail = (error) => {
          alerts.error(error?.response?.data?.msg);
        };

        await sendOtp(value, onSuccess, onFail);
      };

      sendOtpSms({
        phone_number: phone_number,
      });
    } else {
      setHasMounted(true);
    }
  }, [phone_number, hasMounted]);

  const resetPasswordData = (value) => {
    setLoading(true);
    const onSuccess = (token) => {
      setLoading(false);
      navigate(PATH.LOGIN);
      alerts.success("Tạo mật khẩu mới thành công");
    };
    const onFail = (error) => {
      alerts.error(error?.response?.data?.msg);
    };

    resetPassword(value, onSuccess, onFail);
  };

  const tailLayout = {
    wrapperCol: {
      offset: 18,
      span: 28,
    },
  };

  const onFinish = (values) => {
    console.log(values);
    values.phone = phone_number;
    values.otp = otp;
    resetPasswordData(values);
  };

  return (
    <div className="w-[500px] mx-auto border-[1px] border-[#ccc] border-solid p-5 rounded-[6px] mt-[25vh]">
      <div className="text-center">
      <img src={LOGO} className="w-full h-full" alt="logo"></img>
      </div>
      <p className="font-bold text-center text-[20px]">Admin</p>
      <div className="py-[10px] flex justify-center bg-white">
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span className="w-[12px]"></span>}
          inputStyle={{
            width: "40px",
            height: "40px",
            borderRadius: "6px",
            fontSize: "16px",
          }}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="text-center text-[#A0A0A0] pt-[16px] bg-white pb-[20px]">
        <span>
          Mã xác thực (OTP) đã được gửi đến 
        </span>
        <span className="font-semibold text-[#000]">
          {validatePhoneNumber(phone_number) ? " SĐT:" : "Email:"} {phone_number}
        </span>
        <p className="mt-[28px]">
          {timer === 0
            ? "Yêu cầu gửi lại mã mới"
            : `Bạn có thể yêu cầu gửi lại mã mới sau ${timer}s`}{" "}
        </p>
        <p
          className={
            timer > 0
              ? `text-[#ccc] underline w-fit mx-auto`
              : `text-primary underline w-fit mx-auto`
          }
        >
          Gửi lại mã
        </p>
      </div>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item
          label="Mật khẩu"
          name="password"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập Mật khẩu!",
            },
          ]}
          sx={{ marginBottom: "10px" }}
        >
          <Input.Password placeholder=" Nhập mật khẩu" autoComplete="false" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Tiếp tục
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
